<template>
  <v-row>
    <v-col cols="12" v-if="$store.getters.getTodoTasks.length !== 0">
      <TaskTableDashboardComponent :search_show="false" block_name="todo" :tasks="$store.getters.getTodoTasks"/>
    </v-col>
  </v-row>
</template>

<script>
import TaskTableDashboardComponent from "@/module/task/components/Task/TaskTableDashboardComponent";

export default {
  name: "TaskDashboardComponent",
  components: { TaskTableDashboardComponent },
  created() {
    this.tasksReload()
  },
  methods: {
    tasksReload() {
      this.$store.dispatch('myTask')
    }
  }
}
</script>

<style scoped>

</style>