<template>
  <v-card class="mb-6">
    <v-card-title class="d-flex justify-space-between align-center">
      {{ $t('notice.title_last') }}
      <v-btn
          v-if="noticeCan('create')"
          elevation="2"
          color="primary"
          small
          class="mr-3"
          :to="{name: 'notices.create', params: {customer_id: null}}"
      >
        <v-icon size="20" class="mr-1">mdi-plus</v-icon>
        {{ $t('notice.btn.create') }}
      </v-btn>
    </v-card-title>
    <template v-if="!preloader">
      <v-card-text v-if="notices.length > 0">
        <v-simple-table fixed-header dense height="220px">
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">{{ $t('notice.table.customer') }}</th>
              <th class="text-left">{{ $t('notice.table.text') }}</th>
              <th class="text-left">{{ $t('notice.table.created_at') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="notice in notices" :key="notice.id">
              <td>{{ getCustomer(notice.customer_id) }}</td>
              <td v-html="brs(notice.text)"></td>
              <td v-html="brs(notice.created_at)"></td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <template v-else>
        <div class="d-flex align-center justify-center" style="min-height: 220px;">
          <h3>{{ $t('notice.table.no_data_available') }}</h3>
        </div>
      </template>
    </template>
    <template v-else>
      <div style="height: 220px;" class="d-flex align-center justify-center">
        <img src="/assets/images/spiner-b.gif" alt="preloader">
      </div>
    </template>
  </v-card>
</template>

<script>
import {can} from '../middleware/index'

export default {
  name: "NoticeLastList",
  data() {
    return {
      preloader: true,
      notices: [],
      employees: [],
      customers: [],
    }
  },
  async mounted() {
    await this.getCustomers()
    await this.getNotices()
  },
  methods: {
    noticeCan(per) {
      return can(per)
    },
    getCustomer(customer_id) {
      return customer_id !== null ? this.customers.find(customer => customer.id === customer_id).company_name : ''
    },
    async getCustomers() {
      await this.$store.dispatch('customers').then(() => {
        this.customers = this.$store.getters.customers.data
      })
    },
    async getNotices() {
      await this.$store.dispatch('lastNotices').then(() => {
        this.notices = this.$store.getters.notices
        setTimeout(() => {
          this.preloader = false
        }, 300)
      })
    },
    brs(text) {
      if (text !== null) {
        return text.replace(/\n/g, '<br />')
      }
      return text
    },
    strWrap(text) {
      if (text !== null) {
        return `<small>${text.replace(' ', '<br />')}</small>`
      }
      return text
    },
    getDate(data) {
      return data.substr(0, 10).split('-').reverse().join('.') + ' ' + data.substr(11)
    }
  }
}
</script>

<style lang="scss">
.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 36px !important;
  color: #3b3b3b;
}

small {
  //font-size: 12px;
  font-weight: 700;
  & > br {
    display: block;
    margin-bottom: -6px;
    font-size: 2px;
    line-height: 2px;
  }
}
</style>
