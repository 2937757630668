<template>
  <v-container fluid style="min-height: calc(100vh - 48px)">
    <mx-preloader :loader-status="preloader"/>
    <v-row>
      <v-col class="12" md="6" lg="6" xl="4">
        <v-card class="d-flex">
          <div class="my-n1">
            <UserAvatarComponent height="86" width="74" avatar_size="30" :tooltip_disabled="true" :avatar="$store.getters.user.avatar" :first_name="$store.getters.user.first_name" :last_name="$store.getters.user.last_name"/>
          </div>
          <div>
            <v-card-title>
              {{ $t('greeting') }}, {{ user.first_name }}
            </v-card-title>
            <v-card-subtitle>{{ user.position }}</v-card-subtitle>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="4">
       <v-card class="px-6 py-2" style="min-height: 87px;">
         <div class="d-flex align-center justify-space-between">
           <div class="d-flex align-center justify-space-between">
             <div>
               <h3 class=" mr-8 mb-n2">{{ $t('task.title') }}</h3>
               <router-link style="font-size: 12px;" :to="{name: 'task'}">{{ $t('task.menu.my_title') }}</router-link>
             </div>
             <div class="d-flex align-center">
               <v-progress-circular
                   :rotate="-90"
                   :size="66"
                   :width="13"
                   :value="taskInPercent"
                   :color="color"
                   class="mr-3"
               >
                 {{ taskInPercent }}
               </v-progress-circular>
               <div class="d-flex flex-column">
                 <span style="font-size: 13px;">{{ $t('task.status.todo') }} (<strong>{{ $store.getters.getTodoTasks.length }}</strong>)</span>
                 <span style="font-size: 13px;">{{ $t('task.status.in_progress') }} (<strong>{{ $store.getters.getInProgressTasks.length }}</strong>)</span>
                 <span style="font-size: 13px;">{{ $t('task.status.completed') }} (<strong>{{ $store.getters.getCompletedTasks.length }}</strong>)</span>
               </div>
             </div>
           </div>
           <v-btn color="primary" @click="$store.commit('setStatusTaskDrawer', true)">
             <v-icon size="20">fas fa-plus</v-icon>
           </v-btn>
         </div>
       </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="4" class="d-none d-xl-block">
        <v-card>
          <v-card-title>
            Placeholder
          </v-card-title>
          <v-card-subtitle>List</v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <TaskDashboardComponent  />
    <v-row class="mt-3" v-if="!preloader">
      <v-col cols="12" md="12">
        <ConversationLastList />
        <NoticeLastList v-if="mxPermission('notice-read')" />
      </v-col>
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title>
            Placeholder
          </v-card-title>
          <v-card-subtitle>List</v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {can} from "@/middleware/access_middleware";
import NoticeLastList from "@/module/notice/components/NoticeLastList";
import ConversationLastList from "@/module/conversation/components/ConversationLastList";
import TaskDashboardComponent from "@/module/task/components/Task/TaskDashboardComponent";
import UserAvatarComponent from "@/module/task/components/UserAvatarComponent";
import moment from "moment";



export default {
  components: { NoticeLastList, ConversationLastList, TaskDashboardComponent, UserAvatarComponent },
  data() {
    return {
      user: {},
      preloader: true,
    }
  },
  async mounted() {
    this.user = this.$store.getters.user
    setTimeout(() => {
      this.preloader = false
    }, 300)
  },
  computed: {
    color() {
      if (this.taskInPercent >= 85) {
        return 'teal'
      } else if (this.taskInPercent >= 65) {
        return 'amber'
      } else {
        return 'red'
      }
    },
    taskInPercent() {
      const todo = this.percentTasksFilter(this.$store.getters.getTodoTasks).length
      const in_progress = this.percentTasksFilter(this.$store.getters.getInProgressTasks).length
      const completed = this.$store.getters.getTodoTasks.length + this.$store.getters.getInProgressTasks.length
      const result = (todo + in_progress + completed)

      if (completed === 0) {
        return 100
      }

      return parseInt(completed * 100 / result)
    }
  },
  methods: {
    mxPermission(permission) {
      return can(permission)
    },
    percentTasksFilter(tasks) {
      return tasks.filter(task => {
        return this.getEndDateStatus(task.end_time)
      })
    },
    getEndDateStatus(date) {
      return moment(moment().format('YYYY-MM-DD')).isAfter(date) || moment(moment().format('YYYY-MM-DD')).isSame(date)
    },
  }
}
</script>
