var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-4 pt-0"},[_c('v-card-title',{staticClass:"ml-n4"},[_c('div',[_c('span',{staticClass:"mr-3"},[_vm._v(" "+_vm._s(_vm.$t(("task.status." + _vm.block_name)))+" ("),_c('strong',[_vm._v(_vm._s(_vm.tasks.length))]),_vm._v(")")]),_c('router-link',{staticStyle:{"font-size":"12px"},attrs:{"to":{name: 'task'}}},[_vm._v(_vm._s(_vm.$t('task.menu.all_title')))])],1),_c('v-spacer'),_c('v-btn',{staticClass:"ml-2 mr-n4",attrs:{"small":""},on:{"click":_vm.tasksReload}},[_c('v-icon',{attrs:{"color":"primary","size":"14"}},[_vm._v("fas fa-redo")])],1),(_vm.search_show)?_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('task.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),_c('v-data-table',{staticClass:"mx-table",attrs:{"headers":_vm.headers,"items":_vm.tasks.slice(0,5),"search":_vm.search,"custom-filter":_vm.filterOnlyCapsText,"hide-default-footer":"","disable-pagination":""},on:{"click:row":_vm.updateModal},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mb-1 mt-3"},[_c('span',{staticClass:"mx-table-item__name"},[_vm._v(_vm._s(item.name))])]),_c('div',{staticClass:"d-flex align-center",staticStyle:{"margin-top":"5px","margin-bottom":"3px"}},[(item.checklist_status !== null)?_c('div',{staticClass:"d-flex align-center mr-4"},[_c('v-icon',{attrs:{"color":"primary","size":"12"}},[_vm._v("fas fa-layer-group")]),_c('span',{staticClass:"mx-checklist_status ml-1"},[_vm._v(" "+_vm._s(item.checklist_status)+" ")])],1):_vm._e(),(item.comments_count > 0)?_c('div',{staticClass:"d-flex align-center mr-4"},[_c('v-icon',{attrs:{"color":"primary","size":"12"}},[_vm._v("far fa-comment-alt")]),_c('span',{staticClass:"mx-checklist_status ml-1"},[_vm._v(_vm._s(item.comments_count))])],1):_vm._e(),(item.file_path!== null)?_c('div',{staticClass:"d-flex align-center mr-4"},[_c('v-icon',{attrs:{"color":"primary","size":"12"}},[_vm._v("fas fa-paperclip")]),_c('span',{staticClass:"mx-checklist_status ml-1"},[_vm._v("1")])],1):_vm._e()])]}},{key:"item.creator",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center my-3"},[_c('UserAvatarComponent',{attrs:{"height":"32","width":"30","avatar_size":"30","tooltip_disabled":true,"avatar":item.creator.avatar,"first_name":item.creator.first_name,"last_name":item.creator.last_name}}),_c('div',{staticClass:"d-flex flex-column justify-center mx-task-table_item"},[_c('div',{staticClass:"mx-task-table_item-user--name mb-n1"},[_vm._v(" "+_vm._s(_vm.getNameSmall(item.creator.first_name, item.creator.last_name, 17))+" ")]),_c('div',{staticClass:"mx-task-table_item-user--position"},[_vm._v(" "+_vm._s(_vm.textSlice(item.creator.position, 22))+" ")])])],1)]}},{key:"item.users",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-wrap my-3"},_vm._l((item.users),function(user){return _c('div',{key:user.uid},[(item.creator.uid !== user.uid)?[_c('UserAvatarComponent',{attrs:{"height":"32","width":"30","avatar_size":"30","avatar":user.avatar,"first_name":user.first_name,"last_name":user.last_name}})]:_vm._e()],2)}),0)]}},{key:"item.observers",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-wrap my-3"},_vm._l((item.observers),function(user){return _c('div',{key:user.uid},[_c('UserAvatarComponent',{attrs:{"height":"32","width":"30","avatar_size":"30","avatar":user.avatar,"first_name":user.first_name,"last_name":user.last_name}})],1)}),0)]}},{key:"item.importance_status",fn:function(ref){
var item = ref.item;
return [_c('TaskImportanceLabelComponent',{staticStyle:{"font-size":"10px","padding":"5px","border-radius":"2px"},attrs:{"label":item.importance_status}})]}},{key:"item.checklist_status",fn:function(ref){
var item = ref.item;
return [(item.checklist_status !== null)?_c('span',{staticClass:"mx-checklist_status"},[_vm._v(_vm._s(item.checklist_status))]):_vm._e()]}},{key:"item.start_time",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"size":"16","color":"primary"}},[_vm._v("far fa-clock")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.getDate(item.start_time)))])],1)]}},{key:"item.end_time",fn:function(ref){
var item = ref.item;
return [(item.end_time !== null)?_c('span',{class:{'mx-task-table_item-date__overdue': _vm.getEndDateStatus(item.end_time) && _vm.block_name !== 'completed'}},[(!_vm.getEndDateStatus(item.end_time) || _vm.block_name === 'completed')?_c('v-icon',{attrs:{"size":"18","color":"primary"}},[_vm._v("far fa-clock")]):_vm._e(),_vm._v(" "+_vm._s(_vm.getDate(item.end_time))+" ")],1):_c('span',{staticStyle:{"width":"100%","display":"block","padding-left":"16px"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }