<template>
  <v-card class="pa-4 pt-0">
    <v-card-title class="ml-n4">
     <div>
       <span class="mr-3"> {{ $t(`task.status.${block_name}`) }} (<strong>{{ tasks.length }}</strong>)</span>
       <router-link style="font-size: 12px;" :to="{name: 'task'}">{{ $t('task.menu.all_title') }}</router-link>
     </div>
      <v-spacer></v-spacer>
      <v-btn @click="tasksReload" small class="ml-2 mr-n4">
        <v-icon color="primary" size="14">fas fa-redo</v-icon>
      </v-btn>
      <v-text-field v-if="search_show" v-model="search" append-icon="mdi-magnify" :label="$t('task.search')" single-line hide-details></v-text-field>
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="tasks.slice(0,5)"
        :search="search"
        :custom-filter="filterOnlyCapsText"
        hide-default-footer
        disable-pagination
        class="mx-table"
        @click:row="updateModal"
    >
      <template v-slot:item.name="{ item }">
        <div class="mb-1 mt-3">
          <span class="mx-table-item__name">{{ item.name }}</span>
        </div>
        <div style="margin-top: 5px; margin-bottom: 3px;" class="d-flex align-center">
          <div class="d-flex align-center mr-4" v-if="item.checklist_status !== null">
            <v-icon color="primary" size="12">fas fa-layer-group</v-icon>
            <span class="mx-checklist_status ml-1" >
              {{item.checklist_status}}
            </span>
          </div>
          <div class="d-flex align-center mr-4" v-if="item.comments_count > 0">
            <v-icon color="primary" size="12">far fa-comment-alt</v-icon>
            <span class="mx-checklist_status ml-1" >{{ item.comments_count }}</span>
          </div>
          <div class="d-flex align-center mr-4" v-if="item.file_path!== null">
            <v-icon color="primary" size="12">fas fa-paperclip</v-icon>
            <span class="mx-checklist_status ml-1" >1</span>
          </div>
        </div>
      </template>

      <template v-slot:item.creator="{ item }">
        <div class="d-flex align-center my-3">
          <UserAvatarComponent height="32" width="30" avatar_size="30" :tooltip_disabled="true" :avatar="item.creator.avatar" :first_name="item.creator.first_name" :last_name="item.creator.last_name"/>
          <div class="d-flex flex-column justify-center mx-task-table_item">
            <div class="mx-task-table_item-user--name mb-n1">
              {{ getNameSmall(item.creator.first_name, item.creator.last_name, 17) }}
            </div>
            <div class="mx-task-table_item-user--position">
              {{ textSlice(item.creator.position, 22) }}
            </div>
          </div>
        </div>
      </template>

      <template v-slot:item.users="{ item }">
        <div class="d-flex flex-wrap my-3">
          <div v-for="user in item.users" :key="user.uid">
            <template v-if="item.creator.uid !== user.uid">
              <UserAvatarComponent height="32" width="30" avatar_size="30" :avatar="user.avatar" :first_name="user.first_name" :last_name="user.last_name"/>
            </template>
          </div>
        </div>
      </template>

      <template v-slot:item.observers="{ item }">
        <div class="d-flex flex-wrap my-3">
          <div v-for="user in item.observers" :key="user.uid">
            <UserAvatarComponent height="32" width="30" avatar_size="30" :avatar="user.avatar" :first_name="user.first_name" :last_name="user.last_name"/>
          </div>
        </div>
      </template>

      <template v-slot:item.importance_status="{ item }">
        <TaskImportanceLabelComponent style="font-size: 10px; padding: 5px; border-radius: 2px" :label="item.importance_status"/>
      </template>

      <template v-slot:item.checklist_status="{ item }">
        <span class="mx-checklist_status" v-if="item.checklist_status !== null">{{item.checklist_status}}</span>
      </template>


      <template v-slot:item.start_time="{ item }">
        <span class="d-flex align-center">
          <v-icon size="16" color="primary">far fa-clock</v-icon>
          <span class="ml-1">{{ getDate(item.start_time) }}</span>
        </span>
      </template>

      <template v-slot:item.end_time="{ item }">
        <span v-if="item.end_time !== null" :class="{'mx-task-table_item-date__overdue': getEndDateStatus(item.end_time) && block_name !== 'completed'}">
          <v-icon size="18" color="primary" v-if="!getEndDateStatus(item.end_time) || block_name === 'completed'">far fa-clock</v-icon>
          {{ getDate(item.end_time) }}
        </span>
        <span v-else style="width: 100%; display: block; padding-left: 16px;"></span>
      </template>

    </v-data-table>


  </v-card>

</template>

<script>
import UserAvatarComponent from "@/module/task/components/UserAvatarComponent";
import TaskImportanceLabelComponent from "@/module/task/components/Importance/TaskImportanceLabelComponent";
import moment from "moment"

export default {
  name: "TaskTableComponent",
  components: { UserAvatarComponent, TaskImportanceLabelComponent },
  props: {
    block_name: {
      type: String,
      default: 'todo',
      required: true
    },
    search_show: {
      default: true,
      required: false
    },
    tasks: {
      headers: {
        type: Array,
        default: () => [],
        required: true
      },
    },
  },
  data() {
    return {
      search: '',
      headers: [
        { text: this.$t('task.table.name'), value: 'name', width: 360 },
        // { text: this.$t('task.table.checklist_status'), value: 'checklist_status', width: 70 },
        { text: this.$t('task.table.created_by'), value: 'creator', width: 200, sortable: false },
        { text: this.$t('task.table.importance_status'), value: 'importance_status', width: 150, sortable: false},
        { text: this.$t('task.table.start_time'), value: 'start_time', width: 135 },
        { text: this.$t('task.table.end_time'), value: 'end_time', width: 145 },
        { text: this.$t('task.table.executor'), value: 'users', width: 180, sortable: false },
        { text: this.$t('task.table.observer'), value: 'observers', width: 120, sortable: false },
        // { text: this.$t('task.table.status'), value: 'status' },
        // { text: this.$t('task.table.status_percent'), value: 'status_percent' },
        // { text: this.$t('task.table.labels'), value: 'labels' },
      ],
      items: []
    }
  },
  methods: {
    textSlice(text, slice=10) {
      if (text === null) return text
      const prefix = text.length > slice ? ' ...' : ''
      const text_sliced = text.slice(0, slice)

      return text_sliced + prefix
    },
    getNameSmall(first_name, last_name, slice=10) {
      if (first_name === null || last_name === null) {
        return text
      }

      if ((first_name + last_name).length > slice) {
        return first_name[0] + '.' + last_name
      }

      return first_name + ' ' + last_name
    },
    tasksReload() {
      this.$store.dispatch('myTask')
    },
    updateModal(e) {
      this.$store.commit('setTaskId', e.id)
      this.$store.commit('setStatusTaskUpdate', true)
    },
    getDate(date) {
      // return moment(date).format('DD.MM.YYYY') + ' | ' + moment(date).format('HH:MM')
      return moment(date).format('DD.MM.YYYY')
    },
    color(bool) {
      return bool ? 'white' : 'primary';
    },
    getEndDateStatus(date) {
      return moment(moment().format('YYYY-MM-DD')).isAfter(date) || moment(moment().format('YYYY-MM-DD')).isSame(date)
    },
    filterOnlyCapsText (value, search, item) {
      return value != null && search != null && typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1 ||
          item.creator.first_name.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1 ||
          item.creator.last_name.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1
    },
  }
}
</script>

<style lang="scss">
$border: 1px;
$border-color__light: rgba(73, 71, 71, 0.12);
$border-color__dark: rgba(215, 215, 215, 0.12);
$border-radius: 4px;
$margin-bottom: 3px;
$height-td: 52px;


.theme--light.v-data-table, .theme--dark.v-data-table {
  background-color: transparent !important;
}

.theme--light.v-data-table {
  td {
    background-color: #fff;
  }
  tr:hover {
    td:first-child {
      span {
        //text-decoration: underline !important;
      }
    }
    td {
      background-color: #f6f6f6;
    }
  }
}

.theme--dark.v-data-table {
  td {
    //background-color: #fff;
  }
  tr:hover {
    td:first-child {
      span {
        text-decoration: underline !important;
      }
    }
    td {
      background-color: #252525;
    }
  }
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
  border-top: $border solid $border-color__light  !important;
  border-bottom: $border solid $border-color__light !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  border-top: $border solid $border-color__light  !important;
  border-bottom: $border solid $border-color__light !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: transparent !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent !important;
}

.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row),
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
  border-top: $border solid $border-color__dark  !important;
  border-bottom: $border solid $border-color__dark !important;
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  border-top: $border solid $border-color__dark  !important;
  border-bottom: $border solid $border-color__dark !important;
}

.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: transparent !important;
}

.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent !important;
}

.mx-table table {
  border-spacing: 0 $margin-bottom !important;

  tr {
    cursor: pointer;
    td:first-child {
      border-left: $border solid rgba(73, 71, 71, 0.12) ;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
    td:last-child{
      border-right: $border solid rgba(73, 71, 71, 0.12) ;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
  td {
    height: $height-td!important;
  }
}

.mx-table-item__name {
  text-decoration: none;
  font-weight: 500;
  color: #4b4b4b;
}

.mx-task-table_item {
  margin-left: 8px;

  &-user{
    &--name {
      font-size: 13px;
      font-weight: 700;
      color: #5e5e5e
    }
    &--position {
      font-size: 11px;
      color: #5e5e5e
    }
  }
  &-date {
    &__overdue {
      background: #c71e10;
      padding: 6px 20px;
      border-radius: 2px;
      font-size: 13px;
      color: #ffffff;
    }
  }
}

.mx-checklist_status {
  height: 15px;
  padding: 0 6px;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
}

.theme--light {
  .mx-table table {
    tr {
      td:first-child {
        border-color: $border-color__light;
      }
      td:last-child{
        border-color: $border-color__light;
      }
    }
  }
  .mx-task-table_item {
    &-user{
      &--name {
        color: #5e5e5e
      }
      &--position {
        color: #5e5e5e
      }
    }
  }

  .mx-checklist_status {
    background: #f2f2f2;
    color: #595959;
  }
}

.theme--dark {
  .mx-table table {
    tr {
      td:first-child {
        border-color: $border-color__dark;
      }
      td:last-child{
        border-color: $border-color__dark;
      }
    }
  }
  .mx-task-table_item {
    &-user{
      &--name {
        color: #ffffff
      }
      &--position {
        color: #ffffff
      }
    }
  }
  .mx-checklist_status {
    background: #363636;
    color: #ffffff;
  }
}

</style>
